import React from 'react';
import PacienteDisponible from './PacienteDisponible';
import { IconContext } from "react-icons";
import { ImCancelCircle } from 'react-icons/im';
import './SetupTurno.css'
import { withAuth0 } from '@auth0/auth0-react';


class SetupTurno extends React.Component {

  state = {
    box: 0,
    estado: "",
    fila: 0,
    pacientes:[],
    detallePaciente: {},
    duracion:"5"
  };

  actualizoListado = async (event) => {
    event.persist()

    const accessToken = await this.props.auth0.getAccessTokenSilently({
      audience: `https://turnos.marta.api/`,
      scope: "admin:consul",
    });

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}` },
      body: JSON.stringify({ patron: event.target.value })
    };
    fetch("https://martabsantiso.com/api/pacientes/", requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({ pacientes: {} });
          this.setState({ pacientes: result });
        })
    // Nota: es importante manejar errores aquí y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.
  };

  formatearFecha = (dia) => {
    const dias = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sábado"];
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

    return (
      dias[dia.getDay()] + ", " + dia.getDate() + " de " + meses[dia.getMonth()] + " de " + dia.getFullYear()
    );
  };

  formatearHora = () => {

    const horariosPosibles = [0, 0,
      "10:00",
      "10:15",
      "10:30",
      "10:45",
      "11:00",
      "11:15",
      "11:30",
      "11:45",
      "12:00",
      "12:15",
      "12:30",
      "12:45",
      "13:00",
      "13:15",
      "13:30",
      "13:45",
      "14:00",
      "14:15",
      "14:30",
      "14:45",
      "15:00",
      "15:15",
      "15:30",
      "15:45",
      "16:00",
      "16:15",
      "16:30",
      "16:45"
    ];

    return (
      horariosPosibles[this.state.fila]
    );
  };

  componentDidMount () {
    let nuevoEstado = Object.assign({}, this.state);

    if (typeof this.props.turnoSeleccionado.fila !== 'undefined') {
      nuevoEstado.box = this.props.turnoSeleccionado.box;
      nuevoEstado.fila = this.props.turnoSeleccionado.fila;
      nuevoEstado.estado = this.props.turnoSeleccionado.estado;
    }
    else {
      nuevoEstado.box = this.props.turnoSeleccionado.box;
      nuevoEstado.fila = this.props.turnoSeleccionado.ubicacion;
      nuevoEstado.estado = 'asignando';
      nuevoEstado.detallePaciente.nombre = this.props.turnoSeleccionado.nombreyapellido;
      nuevoEstado.detallePaciente.id = this.props.turnoSeleccionado.idPaciente;
      nuevoEstado.duracion = this.props.turnoSeleccionado.duracion;
    }

    this.setState(nuevoEstado)
  }

  modificarTurno = (estadoProximo) => {
    let nuevoEstado = Object.assign({}, this.state);
    nuevoEstado.estado = estadoProximo;
    this.setState(nuevoEstado);
  }

  anclarPaciente = (detallePaciente) => {
    let nuevoEstado = Object.assign({}, this.state);
    nuevoEstado.detallePaciente = detallePaciente;
    this.setState(nuevoEstado);
  }

  desAnclarPaciente = () => {
    let nuevoEstado = Object.assign({}, this.state);
    nuevoEstado.detallePaciente = {};
    nuevoEstado.pacientes = [];
    this.setState(nuevoEstado);

  }


  actualizoDuracion = (event) => {
    let nuevoEstado = Object.assign({}, this.state);
    nuevoEstado.duracion = event.target.value;
    this.setState(nuevoEstado);

  }

  prepararGuardado = async () => {

    const seraOcupado = this.state.estado === 'ocupado'
    const seraLibre = this.state.estado === 'libre'
    const seraAsignando = this.state.estado === 'asignando'
    const eraOcupado = this.props.turnoSeleccionado.estado === 'ocupado'
    const eraLibre = this.props.turnoSeleccionado.estado === 'libre'
    const eraAsignando = typeof this.props.turnoSeleccionado.idPaciente !== 'undefined'

    const accessToken = await this.props.auth0.getAccessTokenSilently({
      audience: `https://turnos.marta.api/`,
      scope: "admin:consul",
    });



    const fechaFormateada = (this.props.fecha.getFullYear() + "-" + (this.props.fecha.getMonth() +1) + "-"
      + this.props.fecha.getDate() + " " + this.formatearHora() );


    if(seraOcupado && eraLibre) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}` },
        body: JSON.stringify({ diayhora: fechaFormateada,
                               tipo: 'ocupacion',
                               box: this.props.turnoSeleccionado.box
        })
      };


      fetch("https://martabsantiso.com/api/agregarturnoocupi/", requestOptions)
        .then(res => res.json())
        .then(
          (result) => {
            //HACER MANEJO DE ERRORES
            this.props.guardarCambios()
          })
    }



    if (seraAsignando && eraLibre) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}` },
        body: JSON.stringify({
          diayhora: fechaFormateada,
          tipo: 'turno',
          box: this.props.turnoSeleccionado.box,
          idPaciente: this.state.detallePaciente.id,
          duracion: this.state.duracion * 15
        })
      };


      fetch("https://martabsantiso.com/api/agregarturnoocupi/", requestOptions)
        .then(res => res.json())
        .then(
          (result) => {
            //HACER MANEJO DE ERRORES
            this.props.guardarCambios()
          })
    }

    if (seraOcupado && eraAsignando) {

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}` },
        body: JSON.stringify({
          idTurno: this.props.turnoSeleccionado.id,
          tipo: 'turno',
        })
      };


      fetch("https://martabsantiso.com/api/borrarturnoocupi/", requestOptions)
        .then(res => res.json())
        .then(
          (result) => {
            const requestOptions = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}` },
              body: JSON.stringify({
                diayhora: fechaFormateada,
                tipo: 'ocupacion',
                box: this.props.turnoSeleccionado.box
              })
            };

            fetch("https://martabsantiso.com/api/agregarturnoocupi/", requestOptions)
              .then(res => res.json())
              .then(
                (result) => {
                  //HACER MANEJO DE ERRORES
                  this.props.guardarCambios()
                })
          })

      }

      if (seraAsignando && eraOcupado) {

        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}` },
          body: JSON.stringify({
            fechayhora: fechaFormateada,
            box: this.props.turnoSeleccionado.box,
            tipo: 'ocupacion',
          })
        };


        fetch("https://martabsantiso.com/api/borrarturnoocupi/", requestOptions)
          .then(res => res.json())
          .then(
            (result) => {
              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${accessToken}` },
                body: JSON.stringify({
                  diayhora: fechaFormateada,
                  tipo: 'turno',
                  box: this.props.turnoSeleccionado.box,
                  idPaciente: this.state.detallePaciente.id,
                  duracion: this.state.duracion * 15
                })
              };

              fetch("https://martabsantiso.com/api/agregarturnoocupi/", requestOptions)
                .then(res => res.json())
                .then(
                  (result) => {
                    //HACER MANEJO DE ERRORES
                    this.props.guardarCambios()
                  })
            })

      }

            if(seraLibre && eraOcupado) {
              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${accessToken}` },
                body: JSON.stringify({
                  fechayhora: fechaFormateada,
                  box: this.props.turnoSeleccionado.box,
                  tipo: 'ocupacion',
                })
              };


              fetch("https://martabsantiso.com/api/borrarturnoocupi/", requestOptions)
                .then(res => res.json())
                .then(
                  (result) => {
                    //HACER MANEJO DE ERRORES
                    this.props.guardarCambios()
                  })

            }

            if (seraLibre && eraAsignando) {

              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${accessToken}` },
                body: JSON.stringify({
                  idTurno: this.props.turnoSeleccionado.id,
                  tipo: 'turno',
                })
              };
              fetch("https://martabsantiso.com/api/borrarturnoocupi/", requestOptions)
                .then(res => res.json())
                .then(
                  (result) => {
                    //HACER MANEJO DE ERRORES
                    this.props.guardarCambios()
                  })
            }

            if (seraAsignando && eraAsignando) {

              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${accessToken}` },
                body: JSON.stringify({
                  idTurno: this.props.turnoSeleccionado.id,
                  tipo: 'turno',
                })
              };
              fetch("https://martabsantiso.com/api/borrarturnoocupi/", requestOptions)
                .then(res => res.json())
                .then(
                  (result) => {
                    const requestOptions = {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}` },
                      body: JSON.stringify({
                        diayhora: fechaFormateada,
                        tipo: 'turno',
                        box: this.props.turnoSeleccionado.box,
                        idPaciente: this.state.detallePaciente.id,
                        duracion: this.state.duracion * 15
                      })
                    };

                    fetch("https://martabsantiso.com/api/agregarturnoocupi/", requestOptions)
                      .then(res => res.json())
                      .then(
                        (result) => {
                          //HACER MANEJO DE ERRORES
                          this.props.guardarCambios()
                        })
                  })
            }


  }


  render() {

    const isLibre = this.state.estado === 'libre'
  const isOcupado = this.state.estado === 'ocupado'
  const isAsignando = this.state.estado === 'asignando'
  const isPacienteElegido = this.state.detallePaciente.id > 0
  const cambioLibre = isLibre && (this.props.turnoSeleccionado.estado === 'ocupado' || typeof this.props.turnoSeleccionado.idPaciente !== 'undefined')
    const cambioOcupado = isOcupado && (this.props.turnoSeleccionado.estado === 'libre' || typeof this.props.turnoSeleccionado.idPaciente !== 'undefined')


  return(

          <div className = "modulo-turnos" >
            <h1 className="titulares">Asignación Turnos</h1>
            <div className="titulares"> <h2> {this.formatearFecha(this.props.fecha)}</h2> </div>
            <div className="titulares"> <h2> Horario:  {this.formatearHora()}</h2> </div>
            <div className="botones-turnos" >
        <div><input className="buton-radial" type="radio" checked={isLibre} onChange={this.modificarTurno.bind(this, 'libre')} name="tipo-ocupacion"></input> Libre </div>
        <div><input className="buton-radial" type="radio" checked={isOcupado} onChange={this.modificarTurno.bind(this, 'ocupado')} name="tipo-ocupacion"></input> Ocupado </div>
        <div><input className="buton-radial" type="radio" checked={isAsignando} onChange={this.modificarTurno.bind(this, 'asignando')} name="tipo-ocupacion"></input> Paciente</div>
            </div>

      { isAsignando && !isPacienteElegido &&
            <div className="botones-turnos" >
              <span>
          Duracion:
              <select className="barraBusqueda" value={this.state.duracion} onChange={this.actualizoDuracion} >
                  <option value="1">15 min</option>
                  <option value="2">30 min</option>
                  <option value="3">45 min</option>
                  <option value="4">1 hora</option>
                  <option value="5">1 hora 15 min</option>
                  <option value="6">1 hora 30 min</option>
                </select>
              </span>
              <p>Paciente:</p>
              <input type="text" className="barraBusqueda" onChange={this.actualizoListado} />
                <ul className="ListaPacientes">
                  {Object.keys(this.state.pacientes).map(key => <PacienteDisponible  key={key} index={key} details={this.state.pacientes[key]}
                    anclarPaciente={this.anclarPaciente} />)}
                </ul>
            </div>
      }

      { isAsignando && isPacienteElegido &&
        <div className="botones-turnos" >
          <span>
          Duracion:
              <select className="barraBusqueda" value={this.state.duracion} onChange={this.actualizoDuracion} >
              <option value="1">15 min</option>
              <option value="2">30 min</option>
              <option value="3">45 min</option>
              <option value="4">1 hora</option>
              <option value="5">1 hora 15 min</option>
              <option value="6">1 hora 30 min</option>
            </select>
          </span>
        <h3>Paciente:</h3>
        <p> {this.state.detallePaciente.nombre} {this.state.detallePaciente.apellido}
          <button className="boton-cancelar-paciente" onClick={this.desAnclarPaciente} >
            <IconContext.Provider value={{ color: 'blue', size: '35px' }}>
              <ImCancelCircle />
            </IconContext.Provider>
        </button>
        </p>
        </div>
      }

      {((isAsignando && isPacienteElegido) || (cambioLibre) || (cambioOcupado) )&&
        <div> <button className="boton-verde" onClick={this.prepararGuardado}> Guardar Cambios</button> </div>
      }
        <div> <button className="boton-rojo" onClick={this.props.cancelarCambios}> Cancelar </button> </div>



         </div>

        );

  }

}

export default withAuth0(SetupTurno);
