import React from 'react';
import { Link } from 'react-router-dom';
import BarraBusqueda from './BarraBusqueda';
import PacienteListado from './PacienteListado'
import { withAuth0 } from '@auth0/auth0-react';




class ListadoPacientes extends React.Component {
  state = {
    pacientes: []
  };

 actualizoListado = async (event) => {
   event.persist()
   const accessToken = await this.props.auth0.getAccessTokenSilently({
     audience: `https://turnos.marta.api/`,
     scope: "admin:consul",
   });


    const requestOptions = {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           Authorization: `Bearer ${accessToken}`,
         },
         body: JSON.stringify({ patron: event.target.value })
       };

   fetch("https://martabsantiso.com/api/pacientes/", requestOptions)
         .then(res => res.json())
         .then(
           (result) => {
             this.setState({ pacientes: {} });
             this.setState({ pacientes: result });
           })
    // Nota: es importante manejar errores aquí y no en
    // un bloque catch() para que no interceptemos errores
    // de errores reales en los componentes.




  };


  render() {
    return(
      <div>
        <h1>Listado de Pacientes</h1>
        <BarraBusqueda actualizoListado={this.actualizoListado}/>
      <ul className="ListaPacientes">
          {Object.keys(this.state.pacientes).map(key => <PacienteListado history={this.props.history}  key={key} index={key} details={this.state.pacientes[key]} />)}
        </ul>
        <Link to="/panelcentral">Volver al inicio</Link>
      </div >
    );
  }
}


export default withAuth0(ListadoPacientes);
