import React from 'react';
import './PanelCentral.css'
import LogoutButton from './logutButton'



class PanelCentral extends React.Component {

  getPacienteList = () => {
    this.props.history.push({
      pathname: '/listadopaciente',
    });
  }

  setNuevoPaciente = () => {
    this.props.history.push({
      pathname: '/nuevopaciente',
    });
  }

  getTurnitos = () => {
    this.props.history.push({
      pathname: '/turnos',
    });
  }

  getListadoDiario = () => {
    this.props.history.push({
      pathname: '/listadodiario',
    });
  }



  render() {

    return (
      <div className="grilla">
        <header className="titulo-general">
          <h1>Marta Santiso</h1>
          <p>Kinesióloga</p>
        </header>
        <div className="opcion1"> <button className="boton" onClick={this.setNuevoPaciente}>Agregar Paciente</button></div>
        <div className="opcion2"> <button className="boton" onClick={this.getPacienteList}>Pacientes</button></div>
        <div className="opcion3"> <button className="boton" onClick={this.getTurnitos} >Turnos</button> </div>
        <div className="opcion4"> <button className="boton" onClick={this.getListadoDiario} >Listado Diario Pacientes</button> </div>
        <div><LogoutButton /> </div>
      </div>
    );
  }
}

export default PanelCentral;
