import React from 'react';
import { Link } from 'react-router-dom';
import Calendar from 'react-calendar';
import styled from 'styled-components'
import './Turnos.css'
import SetupTurno from './SetupTurno';
import { withAuth0 } from '@auth0/auth0-react';



const LibrePar = styled.div`
        grid-column: ${props => props.columna};
        display: flex;
        justify-content: center;
        align-items: center;
        grid-row: ${props => props.fila};
        background-color: rgb(153, 51, 153, 0.3);
        `;

const LibreImpar = styled.div`
        grid-column: ${props => props.columna};
        display: flex;
        justify-content: center;
        align-items: center;
        grid-row: ${props => props.fila};
        background-color: rgb(102, 102, 153, 0.1);
        `;


const Ocupado = styled.div`
        grid-column: ${props => props.columna};
        display: flex;
        justify-content: center;
        background-color: red;
        border-radius: 15px;
        align-items: center;
        grid-row: ${props => props.fila};
        `;

const TurnoDado = styled.div`
        grid-column: ${props => props.columna};
        display: flex;
        justify-content: center;
        align-items: center;
        grid-row: ${props => props.fila};
        `;



class Turnos extends React.Component {

  state = {
    visualizacion: "almanaque",
    estadoHorarios: [],
    turnosTomados:[],
    fecha:'',
  };



  procesarDiaElegido = async (objetoRecibido) => {
    const pedidoFecha = objetoRecibido.getFullYear() + "-" + (objetoRecibido.getMonth()+1) + "-" + objetoRecibido.getDate()

    const accessToken = await this.props.auth0.getAccessTokenSilently({
      audience: `https://turnos.marta.api/`,
      scope: "admin:consul",
    });

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}` },
      body: JSON.stringify({ fecha: pedidoFecha})
    };

    fetch("https://martabsantiso.com/api/turnosdia/", requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          //HACER MANEJO DE ERRORES
          let nuevoEstado = Object.assign({}, this.state);
          nuevoEstado.fecha = objetoRecibido;
          nuevoEstado.visualizacion = 'grilla'
          nuevoEstado.estadoHorarios = result.estadoHorarios
          nuevoEstado.turnosTomados = result.turnosTomados
          this.setState(nuevoEstado);
        })
  }

  formatearFecha = (dia) => {
    const dias = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sábado"];
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre" ]

    return (
      dias[dia.getDay()] + ", " + dia.getDate() + " de " + meses[dia.getMonth()] + " de " + dia.getFullYear()
    );
  };

  imprimirTurnoDado = (turnoDadoRecibido, key) => {
      return (
        <TurnoDado key={key + 1000} columna={turnoDadoRecibido.box + 1} fila={turnoDadoRecibido.ubicacion + "/" + (turnoDadoRecibido.ubicacion + turnoDadoRecibido.duracion)} onClick={this.configurarTurno.bind(this, turnoDadoRecibido)}      ><button className="boton-turno" > {turnoDadoRecibido.nombreyapellido} </button>  </TurnoDado>
      );
  }


  imprimoLibresOcupados = (horarioImprimible, key) => {
    const isLibre = (horarioImprimible.estado === 'libre');
    const isOcupado = (horarioImprimible.estado === 'ocupado');
    const isImPar = (horarioImprimible.fila % 2);

    if (isLibre) {
      if (isImPar) {
        return (<LibrePar key={key} onClick={this.configurarHorario.bind(this, horarioImprimible)}  columna={horarioImprimible.box + 1} fila={horarioImprimible.fila}> <button className="boton-transparente-turno" >Libre!!</button></LibrePar>);
      }
      else{
        return (<LibreImpar key={key} onClick={this.configurarHorario.bind(this, horarioImprimible)}  columna={horarioImprimible.box + 1} fila={horarioImprimible.fila}> <button className="boton-transparente-turno">Libre!!</button></LibreImpar>);
      }


    }
    if (isOcupado){
      return (
        <Ocupado onClick={this.configurarHorario.bind(this, horarioImprimible)} key={key} columna={horarioImprimible.box + 1} fila={horarioImprimible.fila} ><button className="boton-transparente-turno" >OCUPADO</button>  </Ocupado>
      );
    }

  return;
  }


  volverCalendario = () => {
    const estadoInicial = {
      visualizacion: "almanaque",
      estadoHorarios: [],
      turnosTomados: [],
      fecha: '',
    };
    this.setState(estadoInicial);
  }


  cancelarCambios = () => {
    let nuevoEstado = Object.assign({}, this.state);
    nuevoEstado.visualizacion = 'grilla'
    this.setState(nuevoEstado);

  }


    configurarHorario = (turnoSeleccionado) => {
      let nuevoEstado = Object.assign({}, this.state);
      nuevoEstado.visualizacion = "setup"
      nuevoEstado.turnoSeleccionado = turnoSeleccionado;
      this.setState(nuevoEstado);
    }

    configurarTurno= (turnoSeleccionado) => {
      let nuevoEstado = Object.assign({}, this.state);
      nuevoEstado.visualizacion = "setup"
      nuevoEstado.turnoSeleccionado = turnoSeleccionado;
      this.setState(nuevoEstado);
  }

  guardarCambios = async() => {

    const pedidoFecha = this.state.fecha.getFullYear() + "-" + (this.state.fecha.getMonth() + 1) + "-" + this.state.fecha.getDate()

    const accessToken = await this.props.auth0.getAccessTokenSilently({
      audience: `https://turnos.marta.api/`,
      scope: "admin:consul",
    });

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`},
      body: JSON.stringify({ fecha: pedidoFecha })
    };

    fetch("https://martabsantiso.com/api/turnosdia/", requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          //HACER MANEJO DE ERRORES
          let nuevoEstado = Object.assign({}, this.state);
          nuevoEstado.visualizacion = 'grilla'
          nuevoEstado.estadoHorarios = result.estadoHorarios
          nuevoEstado.turnosTomados = result.turnosTomados
          this.setState(nuevoEstado);
        })


  }


  render() {
    const isAlmanaque = (this.state.visualizacion === 'almanaque');
    const isGrilla = (this.state.visualizacion === 'grilla');
    const isSetup = (this.state.visualizacion === 'setup');


    if (isGrilla){
        return (
            <div>
            <h1 className="titulares">Turnos</h1>
            <div className="titulares"> <button className="boton-transparente-turno" onClick= {this.volverCalendario} > <h2> {this.formatearFecha(this.state.fecha)}</h2>  </button>  </div>
            <div className="grilla-turnos">
              <div className="grilla-turnos-titulo-horario">Horario</div>
              <div className="grilla-turnos-titulo-box-1">Box 1</div>
              <div className="grilla-turnos-titulo-box-2">Box 2</div>
              <div className="grilla-turnos-horario">10:00</div>
              <div className="grilla-turnos-horario">10:15</div>
              <div className="grilla-turnos-horario">10:30</div>
              <div className="grilla-turnos-horario">10:45</div>
              <div className="grilla-turnos-horario">11:00</div>
              <div className="grilla-turnos-horario">11:15</div>
              <div className="grilla-turnos-horario">11:30</div>
              <div className="grilla-turnos-horario">11:45</div>
              <div className="grilla-turnos-horario">12:00</div>
              <div className="grilla-turnos-horario">12:15</div>
              <div className="grilla-turnos-horario">12:30</div>
              <div className="grilla-turnos-horario">12:45</div>
              <div className="grilla-turnos-horario">13:00</div>
              <div className="grilla-turnos-horario">13:15</div>
              <div className="grilla-turnos-horario">13:30</div>
              <div className="grilla-turnos-horario">13:45</div>
              <div className="grilla-turnos-horario">14:00</div>
              <div className="grilla-turnos-horario">14:15</div>
              <div className="grilla-turnos-horario">14:30</div>
              <div className="grilla-turnos-horario">14:45</div>
              <div className="grilla-turnos-horario">15:00</div>
              <div className="grilla-turnos-horario">15:15</div>
              <div className="grilla-turnos-horario">15:30</div>
              <div className="grilla-turnos-horario">15:45</div>
              <div className="grilla-turnos-horario">16:00</div>
              <div className="grilla-turnos-horario">16:15</div>
              <div className="grilla-turnos-horario">16:30</div>
              <div className="grilla-turnos-horario">16:45</div>

              {Object.keys(this.state.estadoHorarios).map(key => this.imprimoLibresOcupados(this.state.estadoHorarios[key], key))}
              {Object.keys(this.state.turnosTomados).map(key => this.imprimirTurnoDado(this.state.turnosTomados[key], key))}


            </div>
            <div className="link-regreso"> <Link to="/panelcentral">Volver al Menu</Link></div>
            </div>
        );
    }




    if (isAlmanaque) {
      return (
        <div>
          <h1 className="titulares">Turnos</h1>
          <Calendar className="calendario" onClickDay={this.procesarDiaElegido} />
          <div className="link-regreso"> <Link to="/panelcentral">Volver al Menu</Link></div>
        </div>
        );
    }

    if(isSetup){
      return(
        <SetupTurno  fecha={this.state.fecha} turnoSeleccionado={this.state.turnoSeleccionado} cancelarCambios={this.cancelarCambios} guardarCambios={this.guardarCambios}  />
      );

    }



  }
}





export default withAuth0(Turnos);
