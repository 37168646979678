import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import DetallePaciente from './DetallePaciente';
import ListadoPacientes from "./ListadoPacientes"
import NotFound from "./NotFound"
import NuevoPaciente from './NuevoPaciente';
import PanelCentral from './PanelCentral';
import Turnos from "./Turnos";
import ListadoDiario from "./ListadoDiario";
import Login from "./Login";
import { useAuth0 } from "@auth0/auth0-react";
import ProtectedRoute from "./protected-route";
import Loading from "./Loading";


const Router = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

return (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Login} />
      <ProtectedRoute exact path="/panelcentral" component={PanelCentral} />
      <ProtectedRoute exact path="/nuevopaciente" component={NuevoPaciente} />
      <ProtectedRoute exact path="/listadopaciente" component={ListadoPacientes} />
      <ProtectedRoute exact path="/detallepaciente" component={DetallePaciente} />
      <ProtectedRoute exact path="/turnos" component={Turnos} />
      <ProtectedRoute exact path="/listadodiario" component={ListadoDiario} />
      <ProtectedRoute component={NotFound} />
    </Switch>
  </BrowserRouter>
);
}

export default Router;
