import React from 'react';
import LoginButton from './loginButton';
import './PanelCentral.css'


class Login extends React.Component {


  render() {
    return (
      <div>
        <h1>Gestión de turnos - Marta Santiso</h1>
        <LoginButton />
      </div>

    );
  }
}

export default Login;
