import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button className="boton" onClick={() => logout({ returnTo: 'https://martabsantiso.com/'})}>
      Salir
    </button>
  );
};

export default LogoutButton;
