import React from 'react';
import { Link } from 'react-router-dom';
import Calendar from 'react-calendar';
import './ListadoDiario.css';
import { withAuth0 } from '@auth0/auth0-react';

class ListadoDiario extends React.Component {

  state = {
    visualizacion: "almanaque",
    fecha: '',
    turnosAreportar: []
  };

  volverCalendario = () => {
    const estadoInicial = {
      visualizacion: "almanaque",
      fecha: '',
      turnosAreportar: []
    };
    this.setState(estadoInicial);
  }

  procesarDiaElegido = async (objetoRecibido) => {
    const pedidoFecha = objetoRecibido.getFullYear() + "-" + (objetoRecibido.getMonth() + 1) + "-" + objetoRecibido.getDate()

    const accessToken = await this.props.auth0.getAccessTokenSilently({
      audience: `https://turnos.marta.api/`,
      scope: "admin:consul",
    });

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}` },
      body: JSON.stringify({ fecha: pedidoFecha })
    };

    fetch("https://martabsantiso.com/api/listadodiario/", requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          //HACER MANEJO DE ERRORES
          let nuevoEstado = Object.assign({}, this.state);
          nuevoEstado.fecha = objetoRecibido;
          nuevoEstado.visualizacion = 'listado'
          nuevoEstado.turnosAreportar = result
          this.setState(nuevoEstado);
        })
  }

  imprimoListado = (elemento, clave) => {

    let fechayhorario = new Date()
    fechayhorario.setTime(Date.parse(elemento.diayhora))
    const hora = fechayhorario.getHours() + ":" + ("0" + fechayhorario.getMinutes()).slice(-2);



    return (
      <tr key={clave} >
        <td>{hora}</td>
        <td>{elemento.nombre + " " + elemento.apellido}</td>
        <td>{elemento.telefono}</td>
      </tr>

    );

  }

  formatearFecha = () => {
    const dias = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sábado"];
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    const dia = this.state.fecha;


    return (
      dias[dia.getDay()] + ", " + dia.getDate() + " de " + meses[dia.getMonth()] + " de " + dia.getFullYear()
    );
  };



  render() {
    const isAlmanaque = (this.state.visualizacion === 'almanaque');
    const isListado = (this.state.visualizacion === 'listado');

    if (isListado) {
      return (
        <div className="turnos-recientes">
          <div className="subtitulo"><button className="boton-transparente-turno" onClick={this.volverCalendario}> <h2>Reporte de turnos - {this.formatearFecha()}</h2> </button> </div>
          <table>
            <thead>
            <tr>
              <th>Turno</th>
              <th>Nombre</th>
              <th>Teléfono</th>
            </tr>
            </thead>
            <tbody>
            {Object.keys(this.state.turnosAreportar).map(key => this.imprimoListado(this.state.turnosAreportar[key], key))}
            </tbody>
            </table>
          <div className="link-regreso"> <Link to="/panelcentral">Volver al Menu</Link></div>
        </div>
      );
    }

    if (isAlmanaque) {
      return (
        <div>
          <h1 className="titulares">Resumen de turnos y pacientes</h1>
          <Calendar className="calendario" onClickDay={this.procesarDiaElegido} />
          <div className="link-regreso"> <Link to="/panelcentral">Volver al Menu</Link></div>
        </div>
      );
    }

  };


}


export default withAuth0(ListadoDiario);
