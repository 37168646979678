import React from 'react';
import { Link } from 'react-router-dom';
import { FiSave } from 'react-icons/fi'
import { ImCancelCircle } from 'react-icons/im'
import { IconContext } from "react-icons";
import { withAuth0 } from '@auth0/auth0-react';

class NuevoPaciente extends React.Component {

  state = {
    nombre: '',
    apellido: '',
    operacion: '',
    diagnostico: '',
    tratamiento: '',
    nacimiento: '',
    fecha: '',
    direccion: '',
    telefono: '',
    enfermedad: '',
  };

  cancelarAgregado = (event) => {
    const registroLimpio = {
      nombre: '',
      apellido: '',
      operacion: '',
      diagnostico: '',
      tratamiento: '',
      nacimiento: '',
      fecha: '',
      direccion: '',
      telefono: '',
      enfermedad: '',
    };

    this.setState(registroLimpio);
  }

  engancharEstado = key => {
    return event => {
      this.setState({
        [key]: event.target.value
      });
    };
  };

  guardarPaciente = async (event) => {

    const accessToken = await this.props.auth0.getAccessTokenSilently({
      audience: `https://turnos.marta.api/`,
      scope: "admin:consul",
    });

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`},
      body: JSON.stringify(this.state)
    };

    fetch("https://martabsantiso.com/api/agregar_paciente/", requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          this.props.history.push({
            pathname: '/detallepaciente',
            dataPaciente: { idPaciente: result.nuevo_paciente_id }
          });
        })


  }

  render() {


    return (
        <div>
        <h1>Agregar Paciente</h1>
        <div className="info-general">
          <div className="grilla-subtitulo">
            <h2 className="subtitulo-con-grilla">Información general</h2>
            <div className="grid-boton-accion">
              <button className="boton-transparente" onClick={this.guardarPaciente} >
                <IconContext.Provider value={{ color: 'blue', size: '45px' }}>
                  <FiSave />
                </IconContext.Provider>
              </button>
              <button className="boton-transparente" onClick={this.cancelarAgregado}>
                <IconContext.Provider value={{ color: 'blue', size: '45px' }}>
                  <ImCancelCircle />
                </IconContext.Provider>
              </button>
            </div>
          </div>
          <ul>
            <p> <strong> Nombre:</strong> <input type="text"              onChange={this.engancharEstado('nombre')}       value={this.state.nombre}   className="editarComponente" ></input></p>
            <p> <strong> Apellido:</strong> <input type="text"            onChange={this.engancharEstado('apellido')}       value={this.state.apellido}     className="editarComponente" ></input></p>
            <p> <strong> Operación:</strong> <input type="text"           onChange={this.engancharEstado('operacion')}       value={this.state.operacion}     className="editarComponente" ></input></p>
            <p> <strong> Diagnóstico:</strong> <input type="text"         onChange={this.engancharEstado('diagnostico')}       value={this.state.diagnostico}     className="editarComponente" ></input></p>
            <p> <strong> Tratamiento:</strong> <input type="text"         onChange={this.engancharEstado('tratamiento')}       value={this.state.tratamiento}    className="editarComponente" ></input></p>
            <p> <strong> Fecha de nacimiento:</strong> <input type="text" onChange={this.engancharEstado('nacimiento')}       value={this.state.nacimiento}    className="editarComponente" ></input></p>
            <p> <strong> Dirección:</strong> <input type="text"           onChange={this.engancharEstado('direccion')}       value={this.state.direccion}     className="editarComponente" ></input></p>
            <p> <strong> Teléfono:</strong> <input type="text"            onChange={this.engancharEstado('telefono')}      value={this.state.telefono}    className="editarComponente" ></input></p>
            <p> <strong> Enfermedad:</strong> <input type="text"          onChange={this.engancharEstado('enfermedad')}       value={this.state.enfermedad}    className="editarComponente"></input></p>
        </ul>
        </div>
        <div className="link-regreso"> <Link to="/panelcentral">Volver al Menu</Link></div>
      </div>
    );
  }
}

export default withAuth0(NuevoPaciente);
