import React from 'react';
import { Link } from 'react-router-dom';
import './DetallePaciente.css'
import { FiSave, FiEdit2 } from 'react-icons/fi'
import { ImCancelCircle} from 'react-icons/im'
import { BiMessageAdd } from 'react-icons/bi'
import { IconContext } from "react-icons";
import Observacion from './Observacion';
import  TurnoDetalle  from "./TurnoDetalle";
import { Bar } from 'react-chartjs-2';
import { withAuth0 } from '@auth0/auth0-react';



class DetallePaciente extends React.Component {

  constructor(props) {
    super(props);
    this.nombre = React.createRef();
    this.apellido = React.createRef();
    this.operacion = React.createRef();
    this.diagnostico = React.createRef();
    this.tratamiento = React.createRef();
    this.nacimiento = React.createRef();
    this.direccion = React.createRef();
    this.telefono = React.createRef();
    this.enfermedad = React.createRef();
  }


  state = {
    detalleDePaciente: {
      observaciones:[],
      turnos_proximos:[],
      turnos_recientes: [],
      turnos_historicos: [],
    },
    editInfogeneral: 'desactivado',
    addOBservacion: 'activado',
    datosGraficoOk: false,
  };


  dataGrafico = {
    labels: [],
    datasets: [
      {
        label: 'Cantidad de sesiones',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: []
      }
    ]
  };


  agregarObservacion = (event) => {
    const obsMuleta ={
      id: -1,
      fecha: "",
      titulo: "",
      detalle: ""
    }
    const nuevasObs = [obsMuleta, ...this.state.detalleDePaciente.observaciones ];
    let nuevoEstado = Object.assign({}, this.state);
    nuevoEstado.detalleDePaciente.observaciones = nuevasObs;
    nuevoEstado.addOBservacion = 'desactivado';
    this.setState(nuevoEstado);
  }

  cancelarObservacion = (event) => {
    let backupObs = [...this.state.detalleDePaciente.observaciones];
    backupObs.splice(0,1);
    let nuevoEstado = Object.assign({}, this.state);
    nuevoEstado.detalleDePaciente.observaciones = backupObs;
    nuevoEstado.addOBservacion = 'activado';
    this.setState(nuevoEstado);

  }

  confirmarObservacion = (obsParaConfirmar) => {
    let backupObs = [...this.state.detalleDePaciente.observaciones, obsParaConfirmar];
    backupObs.splice(0, 1);
    let nuevoEstado = Object.assign({}, this.state);
    nuevoEstado.detalleDePaciente.observaciones = backupObs;
    nuevoEstado.addOBservacion = 'activado';
    this.setState(nuevoEstado);
  }

  eliminarObservacion = (obsParaEliminar) => {
    let backupObs = [...this.state.detalleDePaciente.observaciones];
    backupObs.splice(obsParaEliminar, 1);
    let nuevoEstado = Object.assign({}, this.state);
    nuevoEstado.detalleDePaciente.observaciones = backupObs;
    this.setState(nuevoEstado);
  }


  encenderEditado = (event) => {
    this.setState({editInfogeneral: 'activado'})
  }

  cancelarEditado = (event) => {
    this.setState({ editInfogeneral: 'desactivado' })
  }

  guardarCambios = async (event) => {
    event.persist()

    let nuevoEstado = {...this.state};
    nuevoEstado.detalleDePaciente.nombre = this.nombre.current.value;
    nuevoEstado.detalleDePaciente.apellido =    this.apellido.current.value;
    nuevoEstado.detalleDePaciente.operacion =    this.operacion.current.value;
    nuevoEstado.detalleDePaciente.diagnostico =    this.diagnostico.current.value;
    nuevoEstado.detalleDePaciente.tratamiento =    this.tratamiento.current.value;
    nuevoEstado.detalleDePaciente.nacimiento =    this.nacimiento.current.value;
    nuevoEstado.detalleDePaciente.direccion =    this.direccion.current.value;
    nuevoEstado.detalleDePaciente.telefono =    this.telefono.current.value;
    nuevoEstado.detalleDePaciente.enfermedad =    this.enfermedad.current.value;
    nuevoEstado.editInfogeneral = 'desactivado';
    this.setState(nuevoEstado);

    const datosActualizados = {
      idPaciente: this.props.location.dataPaciente.idPaciente,
      nombre:          this.nombre.current.value,
      apellido:      this.apellido.current.value,
      operacion:     this.operacion.current.value,
      diagnostico:  this.diagnostico.current.value,
      tratamiento:  this.tratamiento.current.value,
      nacimiento:  this.nacimiento.current.value,
      direccion:   this.direccion.current.value,
      telefono:     this.telefono.current.value,
      enfermedad:  this.enfermedad.current.value
    }

    const accessToken = await this.props.auth0.getAccessTokenSilently({
      audience: `https://turnos.marta.api/`,
      scope: "admin:consul",
    });


    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' ,
                  Authorization: `Bearer ${accessToken}`,},
      body: JSON.stringify(datosActualizados)
    };

    fetch("https://martabsantiso.com/api/actualizar_paciente/", requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          //HACER MANEJO DE ERRORES
        })

  }


 async componentDidMount() {
    if (typeof this.props.location.dataPaciente !== 'undefined') {
      const accessToken = await this.props.auth0.getAccessTokenSilently({
        audience: `https://turnos.marta.api/`,
        scope: "admin:consul",
      });
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ idPaciente: this.props.location.dataPaciente.idPaciente })
      };
      fetch("https://martabsantiso.com/api/pacientedetail/", requestOptions)
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({ detalleDePaciente: result });
            this.dataGrafico.labels = result.turnos_historicos.map(a => this.formatearMes(a.mes));
            this.dataGrafico.datasets[0].data = result.turnos_historicos.map(a => a.cantidad_turnos);
            this.setState({datosGraficoOk: true });
          })
        // Nota: es importante manejar errores aquí y no en
        // un bloque catch() para que no interceptemos errores
        // de errores reales en los componentes.
    }

}

  formatearMes = (mesRecibido) => {
    const dia = new Date(mesRecibido);
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

    return (
      meses[dia.getMonth()] + ' - ' + dia.getFullYear()
    );
  }


  render() {
    const isEditOn = (this.state.editInfogeneral === 'activado');
    const isAddObsOn = (this.state.addOBservacion === 'activado');

    return (
      <div>
        <h1>Ficha del Paciente</h1>
        <div className="info-general">
          <div className="grilla-subtitulo">
            <h2 className="subtitulo-con-grilla">Información general</h2>
            <div className="grid-boton-accion">
              <button className="boton-transparente" disabled={isEditOn} onClick={this.encenderEditado} >
                <IconContext.Provider  value={{ color: 'blue', size: '45px' }}>
                  <FiEdit2 />
                </IconContext.Provider>
              </button>
              <button className="boton-transparente" disabled={!isEditOn} onClick={this.guardarCambios} >
                <IconContext.Provider value={{ color: 'blue', size: '45px' }}>
                  <FiSave />
                </IconContext.Provider>
              </button>
              <button className="boton-transparente" disabled={!isEditOn} onClick={this.cancelarEditado}>
                <IconContext.Provider value={{ color: 'blue', size: '45px' }}>
                  <ImCancelCircle />
                </IconContext.Provider>
              </button>
            </div>
          </div>
          <ul>
              {isEditOn ?
              <p> <strong> Nombre:</strong> <input type="text" ref={this.nombre} className="editarComponente" defaultValue={this.state.detalleDePaciente.nombre}></input></p>
              : <p> <strong> Nombre: </strong> {this.state.detalleDePaciente.nombre}</p>}
            {isEditOn ?
              <p> <strong> Apellido:</strong> <input type="text" ref={this.apellido} className="editarComponente" defaultValue={this.state.detalleDePaciente.apellido}></input></p>
              : <p> <strong> Apellido:</strong> {this.state.detalleDePaciente.apellido} </p>
              }
            {isEditOn ?
              <p> <strong> Operación:</strong> <input type="text" ref={this.operacion} className="editarComponente" defaultValue={this.state.detalleDePaciente.operacion}></input></p>
              : <p> <strong> Operación:</strong> {this.state.detalleDePaciente.operacion} </p>
              }
            {isEditOn ?
              <p> <strong> Diagnóstico:</strong> <input type="text" ref={this.diagnostico} className="editarComponente" defaultValue={this.state.detalleDePaciente.diagnostico}></input></p>
              : <p> <strong> Diagnóstico:</strong> {this.state.detalleDePaciente.diagnostico} </p>
              }
            {isEditOn ?
              <p> <strong> Tratamiento:</strong> <input type="text" ref={this.tratamiento} className="editarComponente" defaultValue={this.state.detalleDePaciente.tratamiento}></input></p>
              : <p> <strong> Tratamiento: </strong>{this.state.detalleDePaciente.tratamiento} </p>
              }
            {isEditOn ?
              <p> <strong> Fecha de nacimiento:</strong> <input type="text" ref={this.nacimiento} className="editarComponente" defaultValue={this.state.detalleDePaciente.nacimiento}></input></p>
              : <p> <strong> Fecha de nacimiento:</strong> {this.state.detalleDePaciente.nacimiento} </p>
              }
            {isEditOn ?
              <p> <strong> Dirección:</strong> <input type="text" ref={this.direccion} className="editarComponente" defaultValue={this.state.detalleDePaciente.direccion}></input></p>
              : <p> <strong> Dirección: </strong>{this.state.detalleDePaciente.direccion} </p>
              }
            {isEditOn ?
              <p> <strong> Teléfono:</strong> <input type="text" ref={this.telefono} className="editarComponente" defaultValue={this.state.detalleDePaciente.telefono}></input></p>
              : <p> <strong> Teléfono: </strong>{this.state.detalleDePaciente.telefono} </p>
              }
            {isEditOn ?
              <p> <strong> Enfermedad:</strong> <input type="text" ref={this.enfermedad} className="editarComponente" defaultValue={this.state.detalleDePaciente.enfermedad}></input></p>
              : <p> <strong> Enfermedad:</strong> {this.state.detalleDePaciente.enfermedad} </p>
              }
          </ul>
        </div>
        <div className="observaciones">
           <div className="grilla-subtitulo">
            <h2 className="subtitulo-con-grilla">Observaciones</h2>
            <div className="grid-boton-accion">
              <button className="boton-transparente" onClick={this.agregarObservacion} disabled={!isAddObsOn}>
                <IconContext.Provider value={{ color: 'blue', size: '45px' }}>
                  <BiMessageAdd />
                </IconContext.Provider>
              </button>
            </div>
          </div>
            <ul>
            {Object.keys(this.state.detalleDePaciente.observaciones).map(key => <Observacion key={key} index={key} observaData={this.state.detalleDePaciente.observaciones[key]} cancelarObservacion = {this.cancelarObservacion} idPaciente={this.props.location.dataPaciente.idPaciente}
             confirmarObservacion={this.confirmarObservacion}  eliminarObservacion={this.eliminarObservacion} />)}
            </ul>
        </div>
        <div className="proximos-turnos">
          <h2 className="subtitulo">Próximos Turnos</h2>
          <ul>
            {Object.keys(this.state.detalleDePaciente.turnos_proximos).map(key => <TurnoDetalle key={1000+key} index={1000+key}  dataTurno={this.state.detalleDePaciente.turnos_proximos[key]} />)}
          </ul>
        </div>
        <div className="turnos-recientes">
          <h2 className="subtitulo">Sesiones recientes</h2>
            <ul>
            {Object.keys(this.state.detalleDePaciente.turnos_recientes).map(key => <TurnoDetalle key={key} index={key} dataTurno={this.state.detalleDePaciente.turnos_recientes[key]} />)}
            </ul>
        </div>
        <div className="historico-turnos">
          <h2 className="subtitulo">Histórico de Sesiones</h2>
          {this.state.datosGraficoOk ? <Bar data={this.dataGrafico} width={100} height={300} options={{
            maintainAspectRatio: false,
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true
                }
              }]
            }
          }} />
          : <div>Cargando datos... </div>}
        </div>
        <div className="link-regreso">
          <Link  to="/listadopaciente">Volver al listado</Link>
        </div>
      </div>

    );
  }
}

export default withAuth0(DetallePaciente);
