import React from 'react';


const NotFound = () => (
  <div>
    <h2>NotFound!!!       </h2>
  </div>
);


export default NotFound;
