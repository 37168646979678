import React from 'react';
import './PacienteListado.css'



class PacienteDisponible extends React.Component {


    render() {
      const { nombre, apellido } = this.props.details;
      return (
        <ul> <button className="boton" onClick={this.props.anclarPaciente.bind(this, this.props.details)}> {nombre} {apellido} </button></ul>

      );
    }
}

export default PacienteDisponible;


