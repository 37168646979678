import React from 'react';




class TurnoDetalle extends React.Component {

  formatearFecha = (diaRecibido) => {
    const dia = new Date(diaRecibido);
    const dias = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sábado"];
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre" ]
    const misMinutos = dia.getMinutes();
    const misMinsFormateados = ("0" + misMinutos).slice(-2);

    return (
      dia.getHours() + ":" + misMinsFormateados + " -> " + dias[dia.getDay()] + ", " + dia.getDate() + " de " + meses[dia.getMonth()] + " de " + dia.getFullYear()
    );
  };

  render() {
    const turno = this.formatearFecha(this.props.dataTurno.diayhora);
    return (
        <p> {turno} </p>
    );

  }
}

export default TurnoDetalle;
