import React from 'react';
import './PanelCentral.css'


class Loading extends React.Component {


  render() {
    return (
     <div>CARGANDO...</div>
    );
  }
}

export default Loading;
