import React from 'react';
import './PacienteListado.css'



class PacienteListado extends React.Component {

    getPacienteData = () => {
      const idPaciente  = this.props.details.id;
      this.props.history.push({
        pathname: '/detallepaciente',
        dataPaciente: { idPaciente: idPaciente}
      });


    };


    render() {
      const { nombre, apellido } = this.props.details;
      return (
        <ul> <button className="boton" onClick={this.getPacienteData}> {nombre} {apellido} </button></ul>

      );
    }
}

export default PacienteListado;


