import React from 'react';
import './Observacion.css'
import { RiDeleteBin5Line } from 'react-icons/ri';
import { IconContext } from "react-icons";
import { FiSave } from 'react-icons/fi';
import { ImCancelCircle } from 'react-icons/im';
import { withAuth0 } from '@auth0/auth0-react';

class Observacion extends React.Component {

  constructor(props) {
    super(props);
    this.titulo = React.createRef();
    this.detalle = React.createRef();
  }

  borrarObservacion = async (event) => {
    const nuevaObservaParaBorrar = {
      idObservacion: this.props.observaData.id
    }

    const accessToken = await this.props.auth0.getAccessTokenSilently({
      audience: `https://turnos.marta.api/`,
      scope: "admin:consul",
    });

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(nuevaObservaParaBorrar)
    };

    fetch("https://martabsantiso.com/api/eliminar_observacion/", requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
              this.props.eliminarObservacion(this.props.index);
        })

  }



   guardarObservacion = async (event) => {

    const today = new Date();
    const fechaHoy = today.getFullYear() + '-' + (today.getMonth() +1)+ '-' + today.getDate();

     const nuevaObservaParaCargar = {
       idPaciente: this.props.idPaciente,
       fecha: fechaHoy,
       titulo: this.titulo.current.value,
       detalle: this.detalle.current.value,
     }


     const accessToken = await this.props.auth0.getAccessTokenSilently({
       audience: `https://turnos.marta.api/`,
       scope: "admin:consul",
     });

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' ,
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(nuevaObservaParaCargar)
    };

     fetch("https://martabsantiso.com/api/agregar_observacion/", requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          const obsParaConfirmar = {
            id: result.nueva_id_obs,
            fecha: fechaHoy,
            titulo: this.titulo.current.value,
            detalle: this.detalle.current.value,
          };
          this.props.confirmarObservacion(obsParaConfirmar);
        })


  }

  render() {
    const today = new Date();
    const fechaHoy = today.getFullYear() + '-' + (today.getMonth() +1)+ '-' + today.getDate();

    if (this.props.observaData.id > 0 ) {
      return (
        <div className="grilla-observacion">
          <div className="data-obs">
            <p><strong>Fecha: </strong>{this.props.observaData.fecha}</p>
            <p><strong>Título: </strong>{this.props.observaData.titulo}</p>
            <p><strong>Detalle: </strong>{this.props.observaData.detalle}</p>
          </div>
          <button className="boton-borrar-obs" onClick={this.borrarObservacion} >
            <IconContext.Provider value={{ color: 'blue', size: '30px' }}>
              <RiDeleteBin5Line />
            </IconContext.Provider>
          </button>
        </div>
      );
    }
    else{
      return (
        <div className="grilla-observacion-2">
          <div className="data-obs">
            <p><strong>Fecha: </strong>{fechaHoy}</p>
            <p><strong>Título: </strong><input ref={this.titulo} className="editarComponente"  type="text"></input></p>
            <p><strong>Detalle: </strong><input ref={this.detalle} className="editarComponente" type="text"></input></p>
          </div>
          <button className="boton-guardar-obs" onClick={this.guardarObservacion} >
            <IconContext.Provider value={{ color: 'blue', size: '35px' }}>
              <FiSave />
            </IconContext.Provider>
          </button>
          <button className="boton-cancelar-obs" onClick={this.props.cancelarObservacion}  >
            <IconContext.Provider value={{ color: 'blue', size: '35px' }}>
              <ImCancelCircle />
            </IconContext.Provider>
          </button>
        </div>
      );
    }

  }
}

export default withAuth0(Observacion);
